// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import '@scania/tegel/dist/tegel/tegel.css';
@import 'bootstrap/dist/css/bootstrap.min.css';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$administration-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$administration-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$administration-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$administration-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $administration-primary,
      accent: $administration-accent,
      warn: $administration-warn
    )
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($administration-theme);

@import '@scania/colour/dist/scss/colour';
@import '@scania/grid/dist/scss/grid';

@import 'assets/scss/font-awesome.css';
@import '@ng-select/ng-select/themes/default.theme.css';
@import 'ngx-toastr/toastr';

@import 'assets/scss/variables';
@import 'assets/scss/fmp-custom-fonts';
@import 'assets/scss/sdds-base.scss';

/* maintaining compatibility with bootstrap v5 */
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl,
.row {
  --bs-gutter-x: 3rem;
}

.form-row {
  --bs-gutter-x: 1rem;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  margin-bottom: 0.5rem;
}

:root,
html {
  font-size: 10px !important;
}

h3,
h4,
h5,
h6 {
  margin-bottom: 1rem;
}

.app > app-root {
  height: auto;
}

.panel .panel-body {
  padding: 2.6rem 2.4rem;
}

.panel {
  margin-bottom: 15px;

  &-default {
    background-color: $uxpin-white-color;
  }
}

.help-block {
  font-family: 'Scania Sans Semi Condensed', 'Scania Sans Condensed', arial, helvetica, sans-serif;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: var(--sdds-spacing-element-4);
  color: var(--sdds-textfield-helper);

  &.label-warning {
    color: var(--sdds-textfield-helper-error);
  }
}

// .app[pageState='login'] > app-root {
//   /*height: 100vh;*/
// }

:root,
body {
  background-color: #f7f7f7;
  font-family: $sdds-body-font-family;
  font-size: 1.4rem;
}

section.page-container {
  padding: $padding-top 0;
}

.sss-navbar {
  z-index: 1001 !important;
}

.sss-navbar__side-menu--expanded {
  z-index: 1002 !important;
}

.sss-navbar {
  z-index: 1;
}

a.sss-navbar-menu-nav-link {
  text-decoration: none;
}

.form-control {
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-weight: 400;
  padding: 10px 12px;
  height: 42px;
  border-radius: 0;
}

.app textarea.form-control {
  $block-min-height: 120px;
  height: $block-min-height;
  min-height: $block-min-height;
  resize: none;
}

.container {
  margin: 40px auto;
}

.container-fluid {
  width: 100%;
}

.panel-title .toggle-button {
  cursor: pointer;
  font-weight: 400;
  line-height: 0.5em;
}

button {
  outline: none !important;
}

[class^='custom-fa-'],
[class*=' custom-fa-'] {
  font-family: 'fmp-custom-fonts' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app .btn-link.btn-info {
  font-weight: bold;
  text-decoration: none;
  color: var(--sdds-blue-500);
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}

.app .btn-link.btn-info:hover,
.app .btn-link.btn-info:active,
.app .btn-link.btn-info.active {
  color: var(--sdds-blue-400);
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none;
}

//colours
.control-label,
.icon-label {
  display: flex;
  flex-direction: row;
  align-items: center;

  i {
    margin-left: 2px;
    display: flex;
    align-items: center;
    top: 1px;

    &.cui-scania-info-circle {
      position: relative;
    }
  }
}

.tooltip {
  opacity: 1 !important;
  font-size: 12px;
}

.tooltip-inner {
  font-family: 'Scania Sans Semi Condensed', 'Scania Sans Condensed', Arial, Helvetica, sans-serif;
  color: var(--sdds-white);
  background-color: var(--sdds-grey-800);
  padding: 10px;
  border-radius: 4px;
  font-weight: normal;
  text-align: left;
}

.app label,
.control-label,
.app caption {
  font-family: 'Scania Sans Semi Condensed', 'Scania Sans Condensed', Arial, Helvetica, sans-serif;
  font-weight: 400;
  color: var(--sdds-textfield-label-color) !important;
}

//tables.less

.btn + .btn,
.btn + .btn-group {
  margin-left: 7px;
  float: none;
}

.table i {
  white-space: nowrap;
  margin-right: 5px;
}

.location-pin-container {
  position: absolute;
  top: -31px;
  left: -12px;

  .location-pin {
    box-sizing: border-box;
    background: transparent;
    display: inline-block;
    border-radius: 14px 14px 14px 0;
    width: 24px;
    height: 24px;
    border: 7px solid #418bca;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    position: relative;
    box-shadow: -1px 1px 2px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .shadow {
    box-sizing: border-box;
    display: block;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    height: 14px;
    width: 14px;
    left: 50%;
    top: 50%;
    margin: -8px 0 0 5px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-55deg);
    -ms-transform: rotate(-55deg);
    -o-transform: rotate(-55deg);
    transform: rotate(-55deg);
    z-index: -2;
  }
}

.defect-review-icon-container {
  --dot-size: 1rem;
  position: relative;
  width: var(--dot-size);
  height: var(--dot-size);
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 2rem;
    height: 2rem;
    position: relative;
  }

  .indicator {
    position: absolute;
    background-color: var(--sdds-red-600);
    width: var(--dot-size);
    height: var(--dot-size);
    border-radius: 50%;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
  }
}

a {
  cursor: pointer;
}

.table > tbody + tbody {
  border-top: none;
}

.pull-right {
  float: right;
}

dd,
dt {
  line-height: 1.42857143;
}

html,
body {
  height: 100%;
}

.row-spacing {
  & .row + .row {
    margin-top: 1em;
  }
}

/* Toaster css */
body .toast-container .ngx-toastr {
  background: var(--sdds-grey-958);
  color: var(--sdds-white);
  font-size: 14px;
  padding: 6px 10px 6px 10px;
  line-height: 2.2;
  bottom: 28px;
}

.mat-mdc-tab-labels, .mat-mdc-tab-links {
  border-bottom: 1px solid var(--sdds-grey-300);
}

.mdc-tab--active .mdc-tab__text-label {
  color: var(--sdds-grey-958) !important;
  opacity: 1;
}

.mdc-tab__text-label {
  font-weight: bold;
  color: var(--sdds-blue-900) !important;
  opacity: 0.6;
}

.mdc-tab-indicator {
  width: calc(100% - 4.8rem) !important;
  left: 2.4rem !important;
  top: 1px !important;
}

.mdc-tab-indicator__content--underline {
  border-color: var(--sdds-blue-400) !important;
}

#toast-container > .toast-info:before {
  content: url('data:image/svg+xml,%3Csvg%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2032%22%3E%0A%20%20%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M16.013%204.004c-6.642%200-12.026%205.384-12.026%2012.025%200%206.642%205.384%2012.026%2012.026%2012.026%206.641%200%2012.025-5.384%2012.025-12.026%200-6.641-5.384-12.025-12.025-12.025ZM1.987%2016.029c0-7.746%206.28-14.025%2014.026-14.025%207.746%200%2014.025%206.28%2014.025%2014.025%200%207.746-6.28%2014.026-14.025%2014.026-7.746%200-14.026-6.28-14.026-14.026Z%22%20fill%3D%22%232b70d3%22%2F%3E%0A%20%20%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M16.025%2014.126a1%201%200%200%201%201%201v5.616a1%201%200%200%201-2%200v-5.616a1%201%200%200%201%201-1ZM16.025%2010.317a1%201%200%200%201%201%201v.217a1%201%200%201%201-2%200v-.217a1%201%200%200%201%201-1Z%22%20fill%3D%22%232b70d3%22%2F%3E%0A%3C%2Fsvg%3E%0A');
  margin-right: 12px;
  margin-top: 4px;
  width: 20px;
  height: 20px;
  display: block;
}

.toast-container .ngx-toastr.toast-success {
  border-left: 6px solid var(--sdds-positive);
  display: flex;
}

.toast-container .ngx-toastr.toast-error {
  border-left: 6px solid var(--sdds-negative);
  display: flex;
}

.toast-container .ngx-toastr.toast-info {
  border-left: 6px solid var(--sdds-information);
  display: flex;
}

.toast-container .ngx-toastr.toast-warning {
  border-left: 6px solid var(--sdds-warning);
  display: flex;
}

.toast-container .toast-message a {
  color: var(--sdds-blue-300) !important; /* Bootstrap blue color */
  text-decoration: underline;
}

/* Mopinion alignment to TDS */
.mopinion-survey-content {
  label {
    color: #000;
    font-family: 'Roboto';
  }

  input[placeholder],
  textarea[placeholder],
  textarea::placeholder,
  input::placeholder {
    color: rgb(53, 52, 52) !important;
    font-weight: normal !important;
  }
  svg {
    vertical-align: unset !important;
  }
  h1 {
    padding-top: 0 !important;
  }
}

// style alignment with scope cookie consent
scp-cookie-settings {
  --scope-spacing-element-32: 3.2rem;
  --scope-spacing-element-16: 1.6rem;
  --scope-spacing-element-8: 0.8rem;

  * {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  .scope-btn {
    font-size: 1.4rem;
    line-height: 1.6rem;
    border-radius: 0.4rem;
    height: 5.6rem;
    padding: 2rem;

    &.scope-btn-md {
      padding: 1.6rem;
      height: 4.8rem;
    }
  }
}

#surveyContent {
  display: none;
}
