@mixin aside-header-background {
  background-color: $color-info-bright-02;
  height: 6em;
  display: flex;
  align-items: center;
}

@mixin aside-title {
  padding: 0 1em;
  font-size: 20px;
  font-family: $scania-headline;
  text-transform: uppercase;
}

@mixin aside-subtitle {
  padding-left: 1em;
  font-size: 16px;
  font-family: $scania-condensed;
  border-left: 1px solid $color-brand-01;
}

@mixin sdds-input($color, $bg, $border-color, $hover-border-color, $active-border-color) {
  border-radius: 4px 4px 0 0;
  width: 100%;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  border: none;
  outline: none;
  color: $color;
  background-color: $bg;
  font-family: 'Scania Sans Semi Condensed', Arial, Helvetica, sans-serif;
  font-weight: normal;
  line-height: 0;
  letter-spacing: -0.01em;
  padding: 10px;
  border-bottom: 1px solid $border-color;
  transition: border-bottom-color 0.2s ease 0s;

  &:hover {
    border-bottom-color: $hover-border-color;
  }
  &:focus,
  &:active {
    border-bottom-color: $active-border-color;
    box-shadow: none;
  }
  &[disabled] {
    @include sdds-input-disabled();
  }
}

@mixin sdds-input-disabled() {
  color: var(--sdds-textfield-disabled-color);

  &:hover,
  &:focus,
  &:active,
  & {
    border-bottom-color: transparent;
  }
}
