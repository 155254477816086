/*
 * Colors as defined in uxpin
 * https://app.uxpin.com/design-system/06f70c80e5f566b5ec18/colors
 */

$uxpin-white-color: #ffffff;
$uxpin-default-color: #94999d;
$uxpin-info-color: #4a8ba4;
$uxpin-black-color: #242424;
$tab-border: #ddd;
$border-color: #dadada;

$uxpin-light-info-color: #f2f7f9; // Not in uxpin, added for consistency
$uxpin-light-blue: #f6f9fa;
$uxpin-gray-color: #e2e2e2;
$scroll-color: #d9d9d9;
$chip-color: #e8e8e8;

// INTERACTION COLORS
$interaction-warning: #f2855f;

// MAIN BRAND COLORS
$color-brand-01: #041e42; // this is blue-800 from sdds

//FLOW BOOTSTRAP OVERRIDES
//Variables
//Font sizes
$font-size-app: 1.4rem;
$font-size-xxmedium: 14px;

// font families
$scania-condensed-bold: 'Scania Sans Semi Condensed Bold';
$scania-condensed: 'Scania Sans Semi Condensed';
$scania-headline: 'Scania Sans Headline';

//modal vars
$color-primary-03: #53565a;
$color-primary-04: #f6f9fa;
$color-grey3: #f0f0f0;
$color-info-bright: #e4eef1;
$color-info-bright-02: #e8f0f3;
$color-info-bright-03: #d9edf7;
$table-striped-even-color: #ebf2f5;
$uxpin-new-ok-color: #033a6a;

//margin-top after new nav bar
$padding-top: 35px;
$zindex-for-modal: 2000;

//sdds variables
$black: #000000;
$blue-400: var(--sdds-blue-400);
$blue-500: var(--sdds-blue-500);
$grey-50: var(--sdds-grey-50);
$grey-100: var(--sdds-grey-100);
$grey-300: var(--sdds-grey-300);
$grey-400: var(--sdds-grey-400); // #cdd1db
$grey-600: var(--sdds-grey-600);
$grey-800: var(--sdds-grey-800);
$red-600: var(--sdds-red-600);
$sdds-body-font-size: 14px;
$sdds-border-radius: 4px;

$sdds-body-font-family: 'Scania Sans Semi Condensed', Arial, Helvetica, sans-serif;

$datepicker-success-indicator: rgba(29, 171, 139, 0.5);
$datepicker-warning-indicator: rgba(241, 194, 27, 0.5);
$datepicker-error-indicator: rgba(255, 35, 40, 0.4);

$status-colors: (
  danger: var(--sdds-red-600),
  default: var(--sdds-grey-600),
  info: var(--sdds-blue-400),
  success: var(--sdds-positive),
  warning: var(--sdds-warning),
  primary: var(--sdds-blue-600)
);

$status-type-colors: (
  danger: (
    bg: rgba(255, 35, 64, 0.08),
    text: var(--sdds-red-600)
  ),
  info: (
    bg: rgba(43, 112, 211, 0.08),
    text: var(--sdds-blue-400)
  ),
  warning: (
    bg: rgba(241, 194, 27, 0.08),
    text: var(--sdds-warning)
  ),
  success: (
    bg: rgba(29, 171, 139, 0.1),
    text: var(--sdds-positive)
  ),
  default: (
    bg: var(--sdds-grey-100),
    text: var(--sdds-grey-600)
  )
);
