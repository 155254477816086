@use 'mixins';
@import 'variables';

/* By default bg color is white for text /text area/select , provide class 'sdds-bg-grey' for bg color  'grey' */
textarea.form-control,
input.form-control,
input.sdds-text-input,
textarea.sdds-text-input,
div[role='textbox'] {
  @include mixins.sdds-input($black, $uxpin-white-color, $grey-400, $grey-600, $blue-400);
  &.sm {
    min-height: 36px;
    max-height: 36px;
    font-size: 12px;
  }
  &.sdds-bg-grey {
    background-color: $grey-50;
  }
  &.md {
    min-height: 42px;
    max-height: 42px;
    font-size: 14px;
  }
}
